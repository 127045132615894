<template>
  <footer class="bg-white dark:bg-gray-900">
    <div class="container flex flex-col items-center justify-between p-6 mx-auto space-y-4 sm:space-y-0 sm:flex-row">
      <NuxtLink
        class="text-2xl font-bold text-gray-800 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
        to="/"
      >
        {{ $t('films_gratis') }}
      </NuxtLink>

      <p class="text-sm text-gray-600 dark:text-gray-300">
        {{ $t('copyright') }}
      </p>

      <div class="flex -mx-2 invisible" />
    </div>
  </footer>
</template>
